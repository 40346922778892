<template>
  <aside id="sidebar" class="lg:min-h-screen">
    <div
      id="sidebar-wrapper"
      class="
        md:flex 
        lg:fixed lg:z-10 
        md:w-full lg:w-30p
        bg-gray-50
        lg:min-h-screen
        md:p-10 lg:p-12 xl:p-20
        md:flex-row lg:flex-col
        justify-center
      "
    >
      <section
        id="sidebar-info"
        class="
          md:flex
          justify-flex-start
          lg:justify-center
          flex-grow md:flex-row lg:flex-col
          p-8 md:p-0
          print:py-6 print:px-0
        "
        :class="{ 'compact-sidebar': compactSidebar }"
      >
        <SidebarInfo />
        <SidebarContact v-if="showContact" />
      </section>
      <TheNavigation />
    </div>
  </aside>
</template>

<script>
import TheNavigation from "./TheNavigation.vue";
import SidebarInfo from "./sidebar/SidebarInfo.vue"
import SidebarContact from "./sidebar/SidebarContact.vue"

export default {
  components: {
    TheNavigation,
    SidebarInfo,
    SidebarContact,
  },
  data() {
    return {
      compactSidebar: false,
      showContact: false,
    };
  },
  watch: {
    $route(to) {
      if (to.name === "project" || to.name === "portfolio") {
        this.compactSidebar = true;
      } else {
        this.compactSidebar = false;
      }
    },
  },
};
</script>

<style>
html.dark #sidebar-wrapper {
  @apply bg-gray-900;
}

#sidebar-info.compact-sidebar #sidebar-contact {
  @apply hidden;
  @apply lg:block;
}
</style>
