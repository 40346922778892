<template>
  <svg
    id="circle-chevron"
    class="w-12 h-12"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 85 85"
  >
    <circle
      id="chevron-circle"
      class="fill-current text-white"
      cx="42.5"
      cy="42.5"
      r="42.5"
    />
    <path
      id="chevron-arrow"
      d="M37.5,54a1.51,1.51,0,0,1-1.06-.44,1.49,1.49,0,0,1,0-2.12l8.94-8.94-8.94-8.94a1.5,1.5,0,1,1,2.12-2.12l10,10a1.49,1.49,0,0,1,0,2.12l-10,10A1.51,1.51,0,0,1,37.5,54Z"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>