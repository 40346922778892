import es from './es'
import en from './en'

export default {
  locale: 'es',
  messages: {
    es,
    en
  },
  fallbackLocale: 'es'
}