<template>
  <ul
    id="projects"
    :class="layoutStyles"
  >
    <ProjectItem
      v-for="project in portfolio"
      :key="project.id"
      :id="project.id"
      :name="project.name"
      :header="project.header"
      :content="project.content"
    ></ProjectItem>
  </ul>
</template>

<script>
import ProjectItem from "./ProjectItem.vue";

export default {
  props: {
    portfolio: {
      type: Array,
    },
    isFullSize: { 
      type: Boolean,
      default: false,
    },
  },
  computed: {
    layoutStyles() {
      return this.isFullSize ? 'isFullSize' : 'md:grid md:grid-cols-2 md:gap-2';
    }
  },
  components: {
    ProjectItem,
  },
};
</script>

<style>
</style>