export default {
  "main": {
    welcome: "¡Hola mundo!",
    loading: "Cargando...",
    moreProjects: "Más proyectos",
    seeProject: "Ver proyecto",
    switchLang: "Cambiar idioma",
    notFound: "Error 404. Esta página no existe en este sitio."
  },
  sidebar: {
    greetingFirst: "¡Hola! Soy",
    greetingSecond: "Diseñador Gráfico y Web"
  },
  nav: {
    home: "Inicio",
    curriculum: "Currículum",
    portfolio: "Portafolio"
  },
  curriculum: {
    title: "Currículum",
    aboutTitle: "Acerca de mí",
    workTitle: "Experiencia laboral",
    educationTitle: "Educación",
    skillsTitle: "Habilidades",
    knowledgeTitle: "Conocimientos",
    softwareTitle: "Programas",
    presentDay: "Actualidad"
  }
}