export default {
  state: {
    theme: {},
  },
  getters: {
    getTheme(state) {
      return state.theme;
    }
  },
  mutations: {
    setTheme(state, theme) {
      state.theme = theme;
      localStorage.theme = theme;
    }
  },
  actions: {
    initTheme(context) {
      const cachedTheme = localStorage.theme ? localStorage.theme : false;
      const userPrefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;

      if (cachedTheme) {
        context.commit('setTheme', cachedTheme);
      } else if (userPrefersDark) {
        context.commit('setTheme', 'dark');
      } else {
        context.commit('setTheme', 'light');
      }
    },
    toggleTheme(context) {
      switch (localStorage.theme) {
        case 'light':
          context.commit('setTheme', 'dark');
          break;
        default:
          context.commit('setTheme', 'light');
          break;
      }
    }
  }
}