<template>
  <main id="home" class="p-0">
    <ProjectList
      :isFullSize="true"
      :portfolio="featuredPortfolio"
    ></ProjectList>
  </main>
</template>

<script>
import ProjectList from "../components/layout/ProjectList.vue";

export default {
  components: {
    ProjectList,
  },
  computed: {
    portfolio() {
      return this.$store.getters["portfolio/portfolio"];
    },
    featuredPortfolio() {
      return this.$store.getters["portfolio/featuredPortfolio"];
    }
  },
  created() {
    document.title = this.$t("nav.home") + " | Piero del Valle";
  },
};
</script>

<style>
#app {
  @apply flex;
  @apply items-center;
  @apply justify-center;
}
</style>