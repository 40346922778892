import { createStore } from 'vuex';
import curriculumModule from './curriculum/index.js';
import portfolioModule from './portfolio/index.js';
import themeModule from './theme/index.js';

const store = createStore({
    modules: {
        curriculum: curriculumModule,
        portfolio: portfolioModule,
        theme: themeModule,
    }
});

export default store