<template>
  <div id="locale-changer">
    <label for="lang-select" class="hidden lg:inline mr-4">{{ $t("main.switchLang") }}</label>
    <select id="lang-select" name="lang-select" class="p-1" v-model="$i18n.locale">
      <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang.value">
        {{ lang.label }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  data() {
    return {
      langs: [
        {
          label: "Español",
          value: "es",
        },
        {
          label: "English",
          value: "en",
        },
      ],
    };
  },
};
</script>

<style>
html.dark #locale-changer select {
  @apply text-gray-900;
}
</style>