<template>
  <div
    id="hover-controls"
    class="
      fixed z-10
      bottom-16 right-2 md:bottom-2 lg:bottom-auto lg:top-2
      text-sm
      text-gray-900
      bg-white
      p-2 lg:p-3
      print:hidden
    "
  >
    <LanguageSwitcher />
    <ThemeSwitcher />
  </div>
</template>

<script>
import LanguageSwitcher from "./controls/LanguageSwitcher.vue"
import ThemeSwitcher from "./controls/ThemeSwitcher.vue";

export default {
  components: {
    LanguageSwitcher,
    ThemeSwitcher,
  },
};
</script>

<style>
html.dark #hover-controls {
  @apply bg-gray-900;
  @apply text-gray-100;
}
</style>