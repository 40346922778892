<template>
  <ul id="sidebar-contact" class="md:flex-grow lg:flex-grow-0">
    <li
      v-for="contact in contactItems"
      :key="contact.name"
      class="contact-item md:block text-sm lg:text-base print:inline print:mr-2"
    >
      <a
        :href="renderLink(contact.name, contact.content)"
        class="contact-link inline-block my-1 text-red-500"
        target="_blank"
        ><img
          :src="'/img/' + contact.name + '.svg'"
          class="h-6 mr-2 inline"
          alt=""
        />
        <span class="inline-block transition-all">
          {{ contact.handle }}
        </span>
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  computed: {
    contactItems() {
      return this.$store.getters["curriculum/contactInfo"];
    },
  },
  methods: {
    renderLink(type, content) {
      if (type === "email") {
        return "mailto:" + content;
      } else if (type === "phone") {
        return "tel:" + content;
      } else {
        return "https://" + content;
      }
    },
  },
};
</script>

<style>
</style>