import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
  namespaced: true,
  state() {
    return {
      portfolio: [
        {
          id: "donafrida",
          name: "Doña Frida",
          featured: true,
          content: {
            es: {
              skills: ["Identidad de Marca", "Diseño y Desarrollo Web"],
              description: "Doña Frida es una marca exportadora de uvas y mangos de alta calidad de Piura, Peru. Sin embargo, no tenían una identidad de marca ya que operaban bajo el nombre de su empresa matriz.<br/><br/>Su nueva identidad de marca fue diseñada tomando en cuenta la tradición familiar en su negocio, junto con un sitio web para contar su historia, su cultura laboral y sus productos a potenciales inversionistas.",
            },
            en: {
              skills: ["Branding", "Web Design and Development"],
              description: "Doña Frida is a high quality grape and mango exporter from Piura, Peru who wanted to broaden their market to international territories. However, they didn’t have a brand identity as they had been only operating under their parent company.<br/><br/>Their new brand identity was designed to take into account their family traditions as a business.<br/><br/>A new website was developed to showcase their history, their work culture and their products to potential investors.",
            }
          },
          header: "project_donafrida-header.jpg",
          gallery: [
            { "url": "project_donafrida-01.jpg" },
            { "url": "project_donafrida-02.jpg" },
            { "url": "project_donafrida-03.jpg" },
            { "url": "project_donafrida-04.jpg" },
            { "url": "project_donafrida-05.jpg" },
            { "url": "project_donafrida-06.jpg" },
            { "url": "project_donafrida-07.jpg" },
            { "url": "project_donafrida-08.jpg" }
          ]
        },
        {
          id: "atria",
          name: "Atria Energía",
          featured: true,
          content: {
            es: {
              skills: ["Diseño Web"],
              description: "Atria es un distribuidor y generador de energía con operaciones en Perú y Chile. Estaban buscando rediseñar su sitio web para mejorar su ránking de SEO, además de refinar su diseño.<br/><br/>Su sitio fue diseñado tomando en cuenta una estructura apropiada de su contenido para que no sólo tenga mejor posicionamiento en los motores de búsqueda, sino también sea más organizada para sus visitantes.",
            },
            en: {
              skills: ["Web Design"],
              description: "Atria is a energy distributor with operations in Peru and Chile. They were looking to redesign their website to improve its SEO ranking, as well as refine their looks.<br/><br/>Their website was designed to take into consideration a proper structure of their content so it would not only rank better in search engines, but also be better organized for their visitors.",
            }
          },
          header: "project_atria-01.jpg",
          gallery: [
            { "url": "project_atria-01.jpg" },
            { "url": "project_atria-02.jpg" },
            { "url": "project_atria-03.jpg" },
            { "url": "project_atria-04.jpg" },
            { "url": "project_atria-05.jpg" },
            { "url": "project_atria-06.jpg" },
            { "url": "project_atria-07.jpg" },
            { "url": "project_atria-08.jpg" },
            { "url": "project_atria-09.jpg" },
            { "url": "project_atria-10.jpg" },
          ]
        },
        {
          id: "lamusse",
          name: "L'Amusse",
          featured: true,
          content: {
            es: {
              skills: ["Diseño Web"],
              description: "L'Amusse es una tienda online dedicada a vender productos de belleza tales como perfumes, maquillaje y cuidado personal.<br/><br/>Su identidad de marca apunta a representar una estética que inspire belleza, lujo y expresión personal.<br/><br/>De acuerdo con sus lineamientos de marca, su sitio web fue sobrio y minimalista, permitiendo que sus productos resalten.",
            },
            en: {
              skills: ["Web Design"],
              description: "L’Amusse is an upcoming retailer dedicated to selling beauty products such as perfume, skin care and makeup.<br/><br/>Their brand identity aims for an elegant aesthetic that inspires beauty, luxury and self-expression.<br/><br/>In accordance with their brand guidelines their website takes a minimalist approach, allowing their products to stand out.",
            }
          },
          header: "project_lamusse-01.jpg",
          gallery: [
            { "url": "project_lamusse-01.jpg" },
            { "url": "project_lamusse-02.jpg" },
            { "url": "project_lamusse-03.jpg" },
            { "url": "project_lamusse-04.jpg" },
            { "url": "project_lamusse-05.jpg" },
            { "url": "project_lamusse-06.jpg" },
            { "url": "project_lamusse-07.jpg" },
            { "url": "project_lamusse-08.jpg" }
          ]
        },
        {
          id: "mckrestaurants",
          name: "MCK Restaurants",
          featured: true,
          content: {
            es: {
              skills: ["Diseño Web"],
              description: "MCK Restaurants es un holding dedicado al rubro gastronómico. En medio de la pandemia, inauguraron dos nuevos negocios a través del modelo de dark kitchen: Nori Taco y Poke For The People.<br/><br/>Sus sitios web fueron diseñados para complementar su presencia en redes sociales.<br/><br/> Además, una landing page para MCK fue desarrollada para listar sus restaurantes actuales."
            },
            en: {
              skills: ["Web Design"],
              description: "MCK Restaurants is a holding company dedicated to the gastronomic industry. Amidst the pandemic, they released two new business through the “ghost kitchen” model: Nori Taco and Poke For The People.<br/><br/>Their landing pages were designed to complement their social media presence so visitors could give their menu a look and be directed to order online. <br/><br/>A landing page for MCK Restaurants itself was also made to showcase their current businesses."
            }
          },
          header: "project_mck-01.jpg",
          gallery: [
            { "url": "project_mck-01.jpg" },
            { "url": "project_mck-02.jpg" },
            { "url": "project_mck-03.jpg" },
            { "url": "project_mck-04.jpg" },
            { "url": "project_mck-05.jpg" },
            { "url": "project_mck-06.jpg" },
            { "url": "project_mck-07.jpg" },
            { "url": "project_mck-08.jpg" },
          ]
        },
        {
          id: "eradigital",
          name: "Era Digital",
          featured: false,
          content: {
            es: {
              skills: ["Identidad de Marca", "Diseño Web"],
              description: "Era Digital es una agencia enfocada en Growth Marketing, ofreciendo soluciones a través de consultoría y programas online. Luego de la renovación de su identidad de marca, necesitaban un sitio nuevo que explique los contenidos de sus servicios y sus cursos. <br/><br/> Se desarrollaron 3 landing pages diferentes: una general para ambos propósitos, una segunda para leads interesados en sus servicios de agencia y una tercera para quienes estén interesados en sus cursos online.",
            },
            en: {
              skills: ["Branding", "Web Design"],
              description: "Era Digital is an agency specialized in growth marketing which offers solutions through consultancy and online programs. Following their recent rebranding, they needed a new website to explain the contents of their services and their courses.<br/><br/>Their website includes a user flow where they can decide whether their services or their online courses would be a better fit for them, and subsequently schedule a meeting.",
            }
          },
          header: "project_eradigital-01.jpg",
          gallery: [
            { "url": "project_eradigital-01.jpg" },
            { "url": "project_eradigital-02.jpg" },
            { "url": "project_eradigital-03.jpg" },
            { "url": "project_eradigital-04.jpg" },
            { "url": "project_eradigital-05.jpg" },
            { "url": "project_eradigital-06.jpg" },
          ]
        },
        {
          id: "basemedia",
          name: "Base Media",
          featured: false,
          content: {
            es: {
              skills: ["Identidad de Marca", "Diseño y Desarrollo Web"],
              description: "Base Media es una agencia de marketing digital enfocada en generación de leads, embudos de venta y anuncios online.<br/><br/>Actualizaron su sitio web desde una landing page redirigiendo a sus redes sociales a un embudo de ventas donde pudieran explicar su metodología, recolectar contactos de potenciales leads y agendar reuniones con ellos por adelantado.",
            },
            en: {
              skills: ["Branding", "Web Design and Development"],
              description: "Base Media is a digital marketing agency focused on lead generation, sales funnels and online advertisements.<br/><br/>They upgraded their website from a simple landing page redirecting to their social media to a sales funnel website where they could explain their methodology, collect contact information of potential leads and schedule meetings with them in advance.",
            }
          },
          header: "project_basemedia_01.jpg",
          gallery: [
            { "url": "project_basemedia_01.jpg" },
            { "url": "project_basemedia_02.jpg" },
            { "url": "project_basemedia_03.jpg" },
            { "url": "project_basemedia_04.jpg" },
            { "url": "project_basemedia_05.jpg" },
            { "url": "project_basemedia_06.jpg" },
            { "url": "project_basemedia_07.jpg" },
            { "url": "project_basemedia_08.jpg" }
          ]
        },
        {
          id: "blackpeppa",
          name: "Black Peppa",
          featured: false,
          content: {
            es: {
              skills: ["Diseño y Desarrollo Web"],
              description: "Black Peppa es una agencia de márketing con más de 10 años de experiencia especializandose en proyectos audiovisuales como comerciales de TV, contenido multimedia para redes sociales y animación 2D.<br/><br/>Su nueva identidad de marca continuó lo que se trabajó anteriormente, pero con un toque más contemporáneo.<br/><br/>Se hicieron brochures, contenido de redes sociales y flyers fueron diseñados para promover su negocio, así como tambíen un nuevo sitio web.",
            },
            en: {
              skills: ["Web Design and Development"],
              description: "Black Peppa is a marketing agency with over 10 years of experience specializing on audiovisual projects, such as TV commercials, multimedia social media content and 2D animation.<br/><br/>Their new brand identity is a revitalization of their previous one, with a more contemporary feel.<br/><br/>Brochures, social media content and flyers were designed to promote their business, as well as a new website.",
            }
          },
          header: "project_blackpeppa-header.jpg",
          gallery: [
            { "url": "project_blackpeppa-01.jpg" },
            { "url": "project_blackpeppa-02.jpg" },
            { "url": "project_blackpeppa-03.jpg" },
            { "url": "project_blackpeppa-04.jpg" },
            { "url": "project_blackpeppa-05.jpg" },
            { "url": "project_blackpeppa-06.jpg" },
            { "url": "project_blackpeppa-07.jpg" },
            { "url": "project_blackpeppa-08.jpg" },
          ]
        },
        {
          id: "arie",
          name: "Arie",
          featured: false,
          content: {
            es: {
              skills: ["Ilustración"],
              description: "Arie es un centro de rehabilitación y terapia infantil, el cual necesitaba tres videos de inducción para sus nuevos ingresantes. A través de gráficos y caricaturas, se le introduce al espectador la historia, el reglamento y los beneficios del empleado.",
            },
            en: {
              skills: ["Illustration"],
              description: "Arie is a pediatric rehabilitation and therapy center. They offer specialized treatment to disabled newborns, children and teenagers. To introduce their new employees to the work culture, history and benefits, a series of animated shorts were produced.",
            }
          },
          header: "project_arie-header.jpg",
          gallery: [
            { "url": "project_arie-01.jpg" },
            { "url": "project_arie-02.jpg" },
            { "url": "project_arie-03.jpg" },
            { "url": "project_arie-04.jpg" },
            { "url": "project_arie-05.jpg" },
            { "url": "project_arie-07.jpg" },
            { "url": "project_arie-08.jpg" },
            { "url": "project_arie-09.jpg" },
            { "url": "project_arie-10.jpg" }
          ]
        },
      ]

    }
  },
  mutations,
  actions,
  getters,
}