<template>
  <div id="app-container" class="lg:grid w-full">
    <TheSidebar />
    <TheControls />
    <router-view v-slot="slotProps">
      <transition name="route" mode="out-in">
        <component :is="slotProps.Component"></component>
      </transition>
    </router-view>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TheSidebar from "./components/layout/TheSidebar.vue";
import TheControls from "./components/layout/TheControls.vue";

export default {
  data() {
    return {
      imgPath: "./assets/img/",
    };
  },
  components: {
    TheSidebar,
    TheControls
  },
  computed: {
    ...mapGetters({ theme: "getTheme" }),
  },
  watch: {
    theme(newTheme) {
      newTheme === "light"
        ? document.querySelector("html").classList.remove("dark")
        : document.querySelector("html").classList.add("dark");
    },
  },
  provide() {
    return {
      imgPath: this.imgPath,
    };
  },
  beforeMount() {
    this.$store.dispatch("initTheme");
  },
};
</script>

<style>
::-moz-selection {
  @apply text-gray-100;
  @apply bg-red-500;
}

::selection {
  @apply text-gray-100;
  @apply bg-red-500;
}

#app {
  @apply overflow-x-hidden;
  @apply text-gray-800;
  @apply bg-gray-100;

  @apply pb-28;
  @apply md:pb-12;
  @apply lg:pb-0;
}

html.dark #app {
  @apply text-gray-50;
  @apply bg-gray-800;
}

html.dark #app p {
  @apply text-gray-300;
}

main {
  @apply p-6;
  @apply lg:p-16;

  @apply lg:mt-0;

  @apply print:p-0;
}

.route-enter-from {
  opacity: 0.85;
  transform: translateX(30px);
}

.route-leave-to {
  opacity: 0.5;
  transform: translateX(-30px);
}

.route-enter-active {
  transition: all 0.3s ease-out;
}

.route-leave-active {
  transition: all 0.3s ease-in;
}

.route-enter-to,
.route-leave-from {
  opacity: 1;
  transform: translateX(0);
}

#app-container {
  grid-template-columns: 30% 1fr;
}
</style>
