import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
  namespaced: true,
  state() {
    return {
      curriculum: {
        firstname: 'Piero',
        lastname: 'Del Valle',
        content: {
          es: {
            description: 'Soy un diseñador y desarrollador front-end con 5 años de experiencia en diseño gráfico y 3 años de experiencia en diseño web, experiencia de usuario y desarrollo front-end.<br/><br/>Me especializo en Figma para diseñar prototipos, asi como también utilizar frameworks front-end como Vue.js para construir interfaces web. Siempre estoy abierto a aprender nuevas habilidades en experiencia de usuario, diseño de interfaces y desarrollo front-end y back-end.',
            work: [
              {
                organization: 'Base Media',
                from: 'Octubre 2019',
                to: 'Diciembre 2022',
                description: 'Diseñador Gráfico y Web. Diseñé sitios web corporativos, landing pages y e-commerces usando herramientas como Adobe Xd, Figma, Wordpress, Webflow, Elementor.'
              },
              {
                organization: 'Black Peppa',
                from: 'Septiembre 2018',
                to: 'Octubre 2019',
                description: 'Diseñador Gráfico e Ilustrador. Trabajé en ilustraciones en vectores para cortos animados informativos. Diseñé y desarrollé sitios web corporativos con temas personalizados de Wordpress y ACF.'
              },
              {
                organization: 'Out of The Box',
                from: 'Junio 2017',
                to: 'Diciembre 2017',
                description: 'Practicante de Diseñador Gráfico. Trabajé en diseños para redes sociales y catálogos para productos.'
              }
            ],
            education: [
              {
                organization: 'Toulouse Lautrec',
                from: 'Marzo 2013',
                to: 'Diciembre 2016',
                description: 'Carrera de Dirección y Diseño Gráfico.'
              },
              {
                organization: 'Start Up Toulouse',
                from: 'Febrero 2018',
                to: 'Abril 2018',
                description: 'Desarrollo de un modelo de negocio junto a estudiantes de distintas carreras.'
              }
            ],
            skills: [
              {
                id: 'front-end',
                title: 'Desarrollo Front-end',
                content: 'HTML, CSS3, JavaScript, Tailwind, Bootstrap, Bulma, Buefy, Vue.js, NuxtJS, Netlify'
              },
              {
                id: 'uiux',
                title: 'Experiencia e Interfaces de Usuario (UI/UX)',
                content: 'Adobe Xd, Figma, Wireframe and Prototyping, Design Systems',
              },
              {
                id: 'web-design',
                title: 'Diseño Web',
                content:' Webflow, Wordpress, Elementor',
              },
              {
                id: 'graphic-design',
                title: 'Diseño Gráfico',
                content: 'Adobe Photoshop, Adobe Illustrator, Adobe InDesign'
              }
            ]
          },
          en: {
            description: "I’m front-end designer and developer with 5 years of experience in graphic design and 3 years of experience in web design, user experience and front-end development.<br/></br/>I specialize in working with Figma to design prototypes, as well as using front-end frameworks such as Vue.js to build web interfaces. I’m always open to learn new skills in user experience, user interface design, front-end and back-end development.",
            work: [
              {
                organization: 'Base Media',
                from: 'October 2019',
                to: 'December 2022',
                description: 'Web and Graphic Designer. Designed and developed corporate websites, landing pages and online stores using tools such as Adobe Xd, Figma, Wordpress, Webflow, Elementor.'
              },
              {
                organization: 'Black Peppa',
                from: 'September 2018',
                to: 'October 2019',
                description: 'Graphic Designer and Illustrator. Worked on vector illustrations for animted shorts. Designed and developed corporate websites with custom Wordpress themes and ACF.'
              },
              {
                organization: 'Out of The Box',
                from: 'June 2017',
                to: 'December 2017',
                description: 'Graphic Design Intern. Worked on designs for product catalogs and social media posts.'
              }
            ],
            education: [
              {
                organization: 'Toulouse Lautrec',
                from: 'March 2013',
                to: 'December 2016',
                description: 'Management and Graphic Design career.'
              },
              {
                organization: 'Start Up Toulouse',
                from: 'February 2018',
                to: 'April 2018',
                description: 'Development of a business model alongside students from different careers.'
              }
            ],
            skills: [
              {
                id: 'front-end',
                title: 'Front-end Development',
                content: 'HTML, CSS3, JavaScript, Tailwind, Bootstrap, Bulma, Buefy, Vue.js, NuxtJS, Netlify'
              },
              {
                id: 'uiux',
                title: 'User Interface and User Experience (UI/UX)',
                content: 'Adobe Xd, Figma, Wireframe and Prototyping, Design Systems',
              },
              {
                id: 'web-design',
                title: 'Web Design',
                content:' Webflow, Wordpress, Elementor',
              },
              {
                id: 'graphic-design',
                title: 'Graphic Design and Illustration',
                content: 'Adobe Photoshop, Adobe Illustrator, Adobe InDesign'
              }
            ]
          }
        },
        contact: [
          {
            name: 'email',
            handle: 'pierodelvalleb@gmail.com',
            content: 'pierodelvalleb@gmail.com'
          },
          {
            name: 'phone',
            handle: '+51 993 061 313',
            content: '+51 993 061 313'
          },
          {
            name: 'behance',
            handle: '@pierodelvalle',
            content: 'behance.net/pierodelvalle'
          },
        ],
      }
    };
  },
  mutations,
  actions,
  getters,
}