<template>
  <main id="not-found">
    <h1>{{ $t("main.notFound") }}</h1>
  </main>
</template>

<script>
export default {

}
</script>

<style>

</style>