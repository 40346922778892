export default {
  "main": {
    welcome: "Hello world!",
    loading: "Loading...",
    moreProjects: "More projects",
    seeProject: "See project",
    switchLang: "Switch language",
    notFound: "Error 404. This page does not exist in this site."
  },
  sidebar: {
    greetingFirst: "Hello! I'm",
    greetingSecond: "Graphic and Web Designer"
  },
  nav: {
    home: "Home",
    curriculum: "Resume",
    portfolio: "Portfolio"
  },
  curriculum: {
    title: 'Resume',
    aboutTitle: "About me",
    workTitle: "Work experience",
    educationTitle: "Education",
    skillsTitle: "Skills",
    knowledgeTitle: "Knowledge",
    softwareTitle: "Software",
    presentDay: "Now"
  }
}