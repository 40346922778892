export default {
    curriculum(state) {
        return state.curriculum;
    },
    fullName(state) {
        return state.curriculum.firstname + ' ' + state.curriculum.lastname;
    },
    contactInfo(state) {
        return state.curriculum.contact;
    }
}