<template>
  <div id="sidebar-name" class="mb-8 print:m-0 md:w-1/2 lg:w-auto">
    <p
      class="
        text-md
        lg:text-xl
        uppercase
        font-semibold
        mb-2
        print:text-3xl print:font-bold
      "
      data-aos="fade-right"
      data-aos-delay="0"
    >
      {{ $t("sidebar.greetingFirst") }}
      <span class="text-red-500">{{ fullName }}</span>
    </p>
    <p
      class="font-bold text-2xl lg:text-3xl xl:text-4xl print:hidden"
      data-aos="fade-right"
      data-aos-delay="100"
    >
      {{ $t("sidebar.greetingSecond") }}
    </p>
  </div>
</template>

<script>
export default {
  computed: {
    fullName() {
      return this.$store.getters["curriculum/fullName"];
    },
  },
};
</script>

<style>
</style>