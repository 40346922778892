import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import NotFound from '../views/NotFound.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/curriculum',
    name: 'curriculum',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "curriculum" */ '../views/Curriculum.vue'),
  },
  {
    path: '/portafolio',
    name: 'portafolio',
    component: () => import(/* webpackChunkName: "portfolio" */ '../views/Portafolio.vue'),
  },
  {
    path: '/portafolio/:projectId',
    name: 'project',
    component: () => import(/* webpackChunkName: "project" */ '../views/Project.vue'),
    props: true
  },
  {
    name: 'not-found',
    path: '/:notFound(.*)',
    component: NotFound,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(_, _2, savedPosition) {
    // console.log(to, from, savedPosition);
    if (savedPosition) {
      return savedPosition;
    }
    return { left: 0, top: 0 };
  },
  routes
})

export default router
