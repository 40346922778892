<template>
  <nav
    id="nav"
    class="
      flex md:static md:block
      justify-between
      md:flex-grow-0
      w-full md:w-auto
      fixed
      bottom-0
      z-10
      font-display font-bold uppercase
      text-center md:text-right lg:text-left
      text-xs md:text-lg lg:text-2xl
      bg-gray-50 lg:bg-transparent
      print:hidden
    "
  >
    <router-link
      class="nav-link w-1/3 md:w-auto py-4 md:p-0 md:block transition-all"
      @mouseenter="linkIn"
      @mouseleave="linkOut"
      to="/"
      >{{ $t("nav.home") }}</router-link
    >
    <router-link
      class="nav-link w-1/3 md:w-auto py-4 md:p-0 md:block transition-all"
      @mouseenter="linkIn"
      @mouseleave="linkOut"
      to="/curriculum"
      >{{ $t("nav.curriculum") }}</router-link
    >
    <router-link
      class="nav-link w-1/3 md:w-auto py-4 md:p-0 md:block transition-all"
      :class="{ 'router-link-active': subIsActive('/portafolio') }"
      @mouseenter="linkIn"
      @mouseleave="linkOut"
      to="/portafolio"
      >{{ $t("nav.portfolio") }}</router-link
    >
  </nav>
</template>

<script>
export default {
  methods: {
    linkIn(event) {
      event.target.classList.add("is-hovered");
    },
    linkOut(event) {
      event.target.classList.remove("is-hovered");
    },
    subIsActive(input) {
      const paths = Array.isArray(input) ? input : [input];
      return paths.some((path) => {
        return this.$route.path.indexOf(path) === 0; // current path starts with this path string
      });
    },
  }
};
</script>

<style scoped>
html.dark #nav {
  @apply bg-gray-900;
}

#nav .router-link-active {
  @apply text-red-500;
  @apply md:border-none;
  @apply border-b-2;
  @apply border-red-500;
}

.nav-link.is-hovered {
  @apply lg:text-red-500;
  @apply lg:transform;
  @apply lg:translate-x-2;
}
</style>