import { createApp } from 'vue'
import App from './App.vue'
import AOS from 'aos'

import '@/assets/css/tailwind.css'
import 'aos/dist/aos.css'

import router from './router'
import store from './store'

import { createI18n } from 'vue-i18n/index'
import locales from './locales';
const i18n = createI18n(locales)

const app = createApp(App)

app.use(store)
app.use(router)
app.use(i18n)

app.mount('#app')
AOS.init();