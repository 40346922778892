<template>
  <li
    :style="{
      backgroundImage: `linear-gradient(0deg, rgba(0,0,0,0.65), transparent), url(${projectImage})`,
    }"
    class="project-item overflow-hidden relative h-96 mb-4 lg:mb-0 list-none box-border bg-center bg-no-repeat bg-cover text-gray-50"
    @mouseenter="mouseEnter"
    @mouseleave="mouseLeave"
  >
    <router-link :to="projectLink" :title="$t('main.seeProject')" class="project-link" >
      <div
        class="project-wrapper h-full flex items-end justify-between p-8 transition-all"
      >
        <div class="project-text">
          <div class="project-skills mb-2">
            <span
              v-for="skill in localeContent.skills"
              :key="skill"
              class="text-sm lg:text-base font-bold uppercase mr-2 block lg:inline"
              >{{ skill }}</span
            >
          </div>
          <h2 class="font-bold pr-12 text-2xl 2xl:text-3xl leading-none">
            {{ name }}
          </h2>
        </div>
        <IconChevron/>
      </div>
    </router-link>
  </li>
</template>

<script>
import IconChevron from "../icons/IconChevron.vue"

export default {
  props: ["id", "name", "header", "content"],
  inject: ["imgPath"],
  components: {
    IconChevron
  },
  computed: {
    projectLink() {
      return {
        name: "project",
        params: { projectId: this.id },
      };
    },
    projectImage() {
      return "/img/" + this.header;
    },
    localeContent() {
      return this.content[this.$i18n.locale];
    }
  },
  methods: {
    mouseEnter(event) {
      event.target.classList.add("hover");
    },
    mouseLeave(event) {
      event.target.classList.remove("hover");
    },
  },
};
</script>

<style>
#projects.isFullSize .project-item {
  height: 50vh;
  @apply m-0;
  @apply lg:min-h-screen;
  @apply lg:text-6xl;
}

#projects.isFullSize .project-item .project-wrapper {
  @apply lg:p-16;
}

#projects.isFullSize .project-item h2 {
  @apply lg:text-6xl;
}

#projects.isFullSize .project-item span {
  @apply text-sm lg:text-lg;
}

.project-item.hover .project-wrapper {
  @apply transform;
  @apply -translate-y-4;
}

.project-item.hover #circle-chevron {
  @apply animate-bounce;
}

.project-skills span:not(:last-child):after {
  content: " · ";
  @apply ml-2;
}

</style>