<template>
  <button
    @click="toggleTheme"
    class="hidden lg:block fixed top-2 left-2 text-lg"
    tabindex="-1"
  >
    <span v-if="theme == 'dark'">🌙</span>
    <span v-if="theme === 'light'">🌞</span>
  </button>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({ theme: "getTheme" }),
  },
  methods: {
    toggleTheme() {
      this.$store.dispatch("toggleTheme");
    },
  },
};
</script>

<style>
</style>